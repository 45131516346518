<script setup>
import LocationLayout from '@/Layouts/LocationLayout.vue';
import { mapErrors } from '@/utilities';
import { useForm } from '@inertiajs/vue3';

import Button from '@/Components/Button/Button.vue';

// Icons
import saveIcon from '@i/save-white.svg';
import { inject } from 'vue';
const route = inject('route');
const form = useForm({
    phone_number: '',
    email: '',
    vat_number: '',
    kvk_number: '',
    standard_working_week_hours: '',
    bic: '',
    iban: '',
});

const sendForm = () => {
    form.post(route('location.companies.store'), {
        preserveState: true,
        preserveScroll: true,
        onError: (errors) => (form.errors = mapErrors(errors)),
    });
};
</script>

<template>
    <LocationLayout title="Flexable">
        <div class="flex items-end gap-4 mb-6 leading-tight">
            <div class="relative flex flex-col top-2">
                <span class="font-bold text-blue text-8">{{ $t('Create {model}', { model: $t('company') }) }}</span>
            </div>
        </div>
        <div class="grid gap-x-8 md:grid-cols-2">
            <FormKit
                type="text"
                name="coc"
                :label="$t('CoC')"
                v-model="form.kvk_number"
                :errors="form.errors.kvk_number"
                :value="form.kvk_number"
                validation="required"
            />

            <FormKit
                type="text"
                name="vat_number"
                :label="$t('VAT number')"
                v-model="form.vat_number"
                :errors="form.errors.vat_number"
                :value="form.vat_number"
                validation="required"
                :help="$t('Please apply the following format: “NL 123 456 789 B01”')"
            />
            <FormKit
                type="tel"
                name="phone_number"
                :label="$t('General phone number')"
                v-model="form.phone_number"
                :errors="form.errors.phone_number"
                :value="form.phone_number"
            />
            <FormKit
                type="email"
                name="email"
                :label="$t('Administration email address')"
                v-model="form.email"
                :errors="form.errors.email"
                :value="form.email"
            />

            <FormKit
                type="text"
                name="bic"
                :label="$t('BIC')"
                v-model="form.bic"
                :errors="form.errors.bic"
                :value="form.bic"
            />

            <FormKit
                type="text"
                name="iban"
                :label="$t('IBAN')"
                v-model="form.iban"
                :errors="form.errors.iban"
                :value="form.iban"
            />
        </div>

        <div class="flex items-center justify-end w-full">
            <div class="">
                <Button
                    type="button"
                    :processing="form.processing"
                    :recentlySuccessful="form.recentlySuccessful"
                    :text="$t('Save')"
                    :icon="saveIcon"
                    @click="sendForm"
                />
            </div>
        </div>
    </LocationLayout>
</template>
